<template>
  <div :class="b('active-detail')">
    <div v-if="showResult" class="d-flex flex-column h100">
      <div class="mt-4">
        <h2>
          {{ objSelectedService.serviceName }}
        </h2>
      </div>
      <v-divider class="ma-3" />
      <div class="mb-3">
        <b>Найдено врачей: {{ objSearchServiceDetail.length }}</b>
      </div>
      <div class="flex-grow-1">
        <v-row dense>
          <v-col
            cols="12"
            v-for="obj in objSearchServiceDetail"
            :key="obj.doctorId"
          >
            <v-card class="media fill-height mb-auto" hover outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="7">
                    <h4 class="media-heading">{{ obj.doctorName }}</h4>
                    <div>
                      <span class="mb-5">
                        Цена в рублях:
                        <b>{{ obj.doctorServicePrice || obj.servicePrice }}</b>
                      </span>
                    </div>
                    <div class="clinic-name">
                      <span>{{ obj.departmentName }}</span>
                    </div>
                    <div class="city-part">
                      <span> Район города: {{ obj.cityPart }} </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    class="d-flex align-center justify-center"
                  >
                    <v-btn
                      outlined
                      class="ma-2"
                      color="green"
                      v-if="obj.hiddenLk == 0"
                      @click="
                        openDrawerFast(
                          obj.clinicDepartmentServiceId,
                          obj.serviceName,
                          obj.departmentName,
                          obj.departmentId,
                          obj.servicePrice,
                          obj.doctorName,
                          obj.doctorId,
                          obj.cityPart
                        )
                      "
                    >
                      Записаться
                    </v-btn>
                    <v-btn
                      outlined
                      color="red"
                      v-if="obj.hiddenLk == 1"
                      class="fz-10"
                    >
                      Запись ограничена
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="flex-shrink-0">
                <div
                  class="pagination text-center pb-4 pt-3"
                  v-show="objPagination.actual_count > 1 && showResult"
                >
                  <v-pagination
                    v-model="page"
                    :length="objPagination.actual_count"
                    :prev-icon="icons.mdiMenuLeft"
                    :next-icon="icons.mdiMenuRight"
                    @input="getServiceDetail"
                  >
                  </v-pagination>
                </div>
      </div>
    </div>
    <div v-else>
      <v-layout align-center justify-center>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";

const COMPONENT_NAME = "DepartmentCardServiceDetail";
export default {
  name: COMPONENT_NAME,
  data: () => ({
    loading: false,
    page: 1,
    icons: {
      mdiMenuRight,
      mdiMenuLeft,
    },
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  created() {
    this.getServiceDetail();
  },
  computed: {
    ...mapGetters({
      objSearchServiceDetail: names.OBJ_SEARCH_SERVICE_DETAIL,
      entityId: names.ENTITY_ID,
      objSelectedService: names.SELECTED_SERVICE_INFO,
      objPagination: names.OBJ_SEARCH_SERVICE_DETAIL_PAGINATION,
    }),
    showResult() {
      return !isEmpty(this.objSearchServiceDetail);
    },
    apiAddress() {
      return names.API_ADDRESS;
    },
  },
  methods: {
    ...mapActions({
      mainSearchServiceDetail: names.ACTION_MAIN_SEARCH_SERVICE_DETAIL,
      selectedServiceInfo: names.ACTION_SAVE_SELECTED_SERVICE_INFO,
      saveAfterDetailState: names.ACTION_SAVE_AFTER_DETAIL,
    }),

    getServiceDetail() {
      this.mainSearchServiceDetail({
        clinicDepartmentServiceId: this.entityId,
        page: this.page,
      });
    },

    openDrawerFast(
      entityId,
      serviceName,
      departmentName,
      departmentId,
      servicePrice,
      doctorName,
      doctorId,
      cityPart
    ) {
      this.saveAfterDetailState(true);
      this.selectedServiceInfo({
        serviceId: entityId,
        serviceName: serviceName,
        departmentName: departmentName,
        departmentId: departmentId,
        servicePrice: servicePrice,
        doctorName: doctorName,
        doctorId: doctorId,
        cityPart: cityPart,
      });
      this.$drawer.showDrawer(true, "Fast");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

button {
  &:focus {
    outline: none !important;
  }
}

.DepartmentCardServiceDetail {
  .media-body {
    overflow: visible;
  }

  &__content {
    padding: 0 30px;
    margin-left: 70px;
    margin-top: 10px;
    width: calc(100% - 70px);
    height: 88vh;
    position: absolute;
    top: 0;
    bottom: 15vh;
    left: 0;
    transform: translateX(100%);
    transition: all 0.15s ease-out;
    @include tablet-down {
      width: 100%;
    }

    &_active {
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(0);
      transition: all 0.25s ease-out;
    }

    &_activeNav {
      transform: translateX(50%);
      transition: all 0.35s ease-out;
    }

    .btn-primary {
      border-color: $deep-blue;
      color: $deep-blue;
      background-color: white;

      &:hover,
      &:active,
      &:focus {
        background-color: $deep-blue;
        color: white;
      }
    }
  }

  &__back-btn {
    margin-top: 20px;
    margin-bottom: 40px;

    i {
      color: $deep-blue;
    }

    span {
      font-size: 18px;
      position: relative;
      margin-left: 10px;
      color: $deep-blue;

      &:hover {
        transition: all 0.35s ease-out;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__mb-5 {
    margin-bottom: 5px;
    display: inline-block;
  }

  &__active-detail {
    height: 100%;

    .media {
      &:hover {
        box-shadow: 0 2px 6px 5px #e3ecfc;
      }

      .media-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
      }
    }

    .media-body .row {
      display: flex;
      flex-flow: row wrap;

      &:last-child button {
        width: 120px;
        height: 35px;
        background: initial;
      }

      @include tablet-down {
        font-size: 0.6rem;
      }
    }

    .city-part {
      span {
        margin-right: 10px;
        color: $grey70;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .clinic-name {
      font-size: 14px;
      line-height: 18px;
      color: #3446a8;
    }

    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 25px;
      color: #262626;
    }

    .bg-success {
      padding: 15px;
    }
  }

  span {
    color: #000;
  }

  @include phone-only {
    p {
      padding-left: 0;
    }
    label {
      font-size: 15px;
    }

    .main-result-service-detail__content_active {
      padding: 15px 15px 30px;
      margin: 50px 10px 10px 40px;
      @include tablet-down {
        margin: 50px 10px 10px 1px;
      }
    }

    .main-result-service-detail__active-detail {
      .media {
        margin-top: 0;
        border-radius: 4px;
        border: 1px solid #f2f2f2;
        padding: 10px;

        span {
          font-size: 15px;
          @include phone-only {
            font-size: 15px;
          }
        }

        .media-heading {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }
      }

      .media-body .row {
        display: flex;
        flex-flow: row wrap;

        div {
          margin: auto;
        }
      }
    }
  }

  .rating-component {
    .clarification {
      .dropdown-clarification {
        .dropdown-menu {
          @include phone-only {
            left: -20px !important;
          }
        }
      }
    }
  }
}
</style>
